import React, { useEffect } from 'react'

import { PlusIcon } from '@radix-ui/react-icons'
import { AnimatePresence, motion } from 'framer-motion'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import { useCommonQuestions } from 'content-queries/mx/common-questions'
import fireEvent from 'context/tracking/events'
import IconQuestions from 'mx/how-it-works/images/icon-questions.svg'
import Layout from 'mx/layout'
import { Section } from 'mx/section'

const Question = ({
  question,
  isLast,
}: {
  isLast?: boolean
  question: {
    slotTitle: string
    markdown?: any
    textparagraphquote?: { textparagraphquote?: string }
  }
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <motion.header
        initial={false}
        tw="cursor-pointer w-full px-4 py-6 border-gray-300 border-b border-dashed flex justify-between items-center text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent transform transition-colors"
        onClick={() => setExpanded(expanded ? false : true)}
        css={[!isLast && tw`border-b`]}
      >
        <p tw="text-xl font-light md:font-thin text-gray-900 xs:(text-2xl) md:(text-3xl)">
          {question.slotTitle}
        </p>
        <div css={[expanded && tw`transform rotate-45`]}>
          <PlusIcon color="currentColor" width="24px" height="24px" />
        </div>
      </motion.header>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.4 }}
          >
            <div tw="flex px-4 py-4">
              <div tw="w-full md:(w-9/12)">
                {question.markdown ? (
                  <div tw="prose font-light">
                    {renderRichText(question?.markdown)}
                  </div>
                ) : (
                  <div
                    tw="prose mx-auto font-light"
                    dangerouslySetInnerHTML={{
                      __html:
                        question?.textparagraphquote?.textparagraphquote ?? '',
                    }}
                  />
                )}
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}

type categoryProps = {
  moduleTitle: string
  moduleMisc: {
    identifier: string
  }
  contentSlots: {
    slotTitle: string
    textparagraphquote: { textparagraphquote?: string; markdown?: any }
  }[]
}

const FaqPage = ({ path }: { path: string }) => {
  const { data } = useCommonQuestions()

  const {
    node: { title, description, sectionModules: categories },
  } = data[0]

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Homepage',
    })
  }, [])

  return (
    <Layout path={path}>
      <Section>
        <div tw="px-4 grid grid-cols-6 gap-y-6 auto-rows-min md:(grid-cols-16 items-end px-0 pt-0 pl-10) lg:(pl-0 gap-y-8 auto-rows-auto)">
          <div tw="col-start-1 col-end-7  md:(col-start-3 col-end-16) lg:(max-w-3xl shrink-0) xl:(col-start-3 col-end-15 max-w-none)">
            <h1 tw="text-4xl font-thin leading-tight">{title}</h1>
          </div>
          <div tw="col-start-1 col-end-7 md:(col-start-3 col-end-16) lg:(shrink-0 max-w-3xl)  xl:(col-start-3 col-end-15 max-w-none)">
            <p tw="max-w-md font-light text-gray-800 leading-relaxed md:(text-xl)">
              {description}
            </p>
          </div>
        </div>
        <div tw="hidden absolute top-16 md:(block right-48)">
          <img tw="w-48" src={IconQuestions} />
        </div>
      </Section>
      <Section tw="">
        <div tw="flex relative">
          <div tw="hidden pl-10 md:(block w-3/12)">
            <div tw="sticky top-16 space-y-4">
              <p tw="capitalize text-base text-gray-600">Categories</p>
              <div tw="space-y-2">
                {categories.map((category: categoryProps) => (
                  <div key={category.moduleTitle}>
                    <a
                      tw="text-base text-gray-900 font-medium"
                      href={`#${category?.moduleMisc?.identifier}`}
                    >
                      {category.moduleTitle}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div tw="w-full space-y-16 md:(w-9/12)">
            {categories.map((category: categoryProps, idx: number) => {
              return (
                <div key={idx}>
                  <p
                    tw="text-red-900 text-3xl font-thin mb-4 pl-4 md:(text-4xl)"
                    id={`${category?.moduleMisc?.identifier}`}
                  >
                    {category.moduleTitle}
                  </p>
                  {category.contentSlots &&
                    category.contentSlots.map((question, i) => {
                      return (
                        <Question
                          isLast={i === category?.contentSlots.length - 1}
                          key={i}
                          question={question}
                        />
                      )
                    })}
                </div>
              )
            })}
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default FaqPage

export const Head = () => {
  const meta: [{ name: string; content: string }?] =
    process.env.GATSBY_ACTIVE_ENV === 'production'
      ? [
          {
            name: `p:domain_verify`,
            content: '326031e6a77efa67405cff2d7dc2b561',
          },
        ]
      : []
  return (
    <SEO
      description="Find answers to common questions about FORM Kitchens, from product details to installation tips and customer support."
      path="/faqs"
      meta={meta}
      title="FAQs"
    />
  )
}
